h2 {
  transition: 100ms all ease;
  opacity: 0;
  will-change: opacity;
  color: red;
  font-weight: bold;
  font-size: 2.5em;
}
.show {
  opacity: 1;
}