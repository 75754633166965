body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:#000;
  color: #FFF;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Dynalight";
  src: url("./fonts/Dynalight-Regular.ttf") format("truetype");
 }

#root{
  margin: auto;
  min-width: 200px;
  width: 80%;
  /* border: 1px solid red; */
  text-align: center;
  background-color: transparent;
  color: #FFF;
  position: relative;
}
h1 {
  font-family: 'Dynalight';
  font-size: 3em;
}

#boxParameters {
  position: absolute;
  top: 0;
  right: 0;
  /* border: 1px solid yellow; */
  width: 80px;
  background-color: black;
}
#boxParameters > button {
  font-size: 1.5em;
  background-color: transparent;
  color: white;
  float: right;
  border: 0;
}
#volume-box > button {
  font-size: 1.5em;
  background-color: transparent;
  color: white;
  border: 0;
}
#boxParameters button:hover {
  background-color: #FFF;
  color: #000;
  cursor: pointer;
}
#boxParameters button:focus {
  outline: 0;
}

#btnStartSession {
  font-size: 1.2em;
  padding: 0.2em 0.8em;
  cursor: pointer;
  background-color: transparent;
  color: white;
  font-weight: bold;
}
#btnStartSession:hover {
  box-shadow: 0px 0px 10px #EEE;
  background-color: #333;
}