.slider-wrapper {
    display: inline-block;
    width: 20px;
    height: 150px;
    padding: 0;
    background-color: white;
}
.slider-wrapper input {
    width: 150px;
    height: 20px;
    margin: 0;
    transform-origin: 75px 75px;
    transform: rotate(-90deg);
}
#volume-box > h4 {
    line-height: 0em;
    margin-top: 1.2em;
    margin-bottom: 0.8em;
}
